import FontFaceObserver from 'fontfaceobserver';
import { ICON_SPRITE_URL } from './constants';
import loadSvgSprite from './utils/load-svg-sprite';

/**
 * Checks for sessionStorage & Promise
 */
const canPromises = () => 'Promise' in window && 'all' in window.Promise;
const canSessionStorage = () => {
  const sessionStorageCheck = 'mod';

  try {
    sessionStorage.setItem(sessionStorageCheck, sessionStorageCheck);
    sessionStorage.removeItem(sessionStorageCheck);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Load fonts via FontFaceObserver if supported
 */
if ((canSessionStorage() && sessionStorage.fontsLoaded) || !canPromises()) {
  document.documentElement.className += ' has-optimized-font has-headline-font has-fonts';
} else {
  const optimizedFonts = [
    new FontFaceObserver('Gudea Optimized'),
  ];

  const headlineFonts = [
    new FontFaceObserver('EB Garamond'),
    new FontFaceObserver('EB Garamond', { style: 'italic' }),
    new FontFaceObserver('EB Garamond', { weight: '600' }),
    new FontFaceObserver('EB Garamond', { weight: '600', style: 'italic' }),
  ];

  const fonts = [
    new FontFaceObserver('Gudea'),
    new FontFaceObserver('Gudea', { weight: 'bold' }),
    new FontFaceObserver('Gudea', { style: 'italic' }),
    new FontFaceObserver('Gudea', { style: 'italic', weight: 'bold' }),
  ];

  Promise.all(optimizedFonts.map(font => font.load())).then(() => {
    document.documentElement.className += ' has-optimized-font';

    Promise.all(headlineFonts.map(font => font.load())).then(() => {
      document.documentElement.className += ' has-headline-font';
    });

    Promise.all(fonts.map(font => font.load())).then(() => {
      document.documentElement.className += ' has-fonts';
      sessionStorage.fontsLoaded = true;
    });
  });
}

/**
 * Inject icons
 */
loadSvgSprite(ICON_SPRITE_URL);
