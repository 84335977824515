const environment = window.bawu || {};

export const ASSET_BASE_URL = environment.ASSET_BASE_URL || '/';

export const ICON_SPRITE_URL = environment.ICON_SPRITE_URL || '/icons/icons.svg';

export const LANG = document.documentElement.lang || 'de';

export const MEDIA_QUERIES = {
  s: '(max-width: 400px)',
  m: '(min-width: 580px)',
  l: '(min-width: 830px)',
  xl: '(min-width: 1100px)',
  headernav: '(min-width: 1025px)',
};

export const TENANT_COLORS = {
  lp: '#917102',
  bp: '#c04151',
  'm-stm': '#666',
  'm-im': '#b16110',
  'm-fm': '#232380',
  'm-mwk': '#29778b',
  'm-um': '#296e4b',
  'm-wm': '#950000',
  'm-sm': '#3c77b1',
  'm-mlr': '#39852e',
  'm-vm': '#036155',
  'm-jum': '#5a007c',
  'm-km': '#cb3300',
};

export const i18n = {
  en: {
    calendar: {
      previousMonth: 'Previous Month',
      nextMonth: 'Next Month',
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
  },
  de: {
    calendar: {
      previousMonth: 'Vorheriger Monat',
      nextMonth: 'Nächster Monat',
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    },
  },
};
